window.Accordion = (tab) => {
	return {
		activeTab: tab,
	
		activateTab (title, isOpen) {
			if (title == this.activeTab) {
				return !isOpen;
			}
			this.activeTab = title;
			return true;
		},
	
		init () {
	
		}
	};
}


